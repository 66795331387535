import {
  bolApi,
  getJson,
  getText,
  postJsonWithNullBody,
} from "#app/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

export type HeldawayUserRegistration = {
  registered: boolean;
  registeredVerification: boolean;
};

export type HeldawayAccessToken = {
  session: string;
  token: string;
  submitUrl: string;
};

export type YodleeAmountDTO = {
  amount: number;
  currency: string;
};

export type YodleeNetWorthDTO = {
    date: Date;
    asset: YodleeAmountDTO;
    liability: YodleeAmountDTO;
    netWorth: YodleeAmountDTO;
}

export type AccountsRefreshedResult = {
    IsRefreshed: boolean,
    LastRefreshed?: Date
}

const GATEWAY_PATH = "heldaway/v1";

export const heldawayQueryKeys = createQueryKeys("heldaway", {
  register: {
    queryKey: null,
    queryFn: () =>
      bolApi
        .get(`${GATEWAY_PATH}/User/Register`)
        .json() satisfies Promise<HeldawayUserRegistration>,
  },
  accessToken: {
    queryKey: null,
    queryFn: () =>
      bolApi
        .get(`${GATEWAY_PATH}/User/AccessToken`)
        .json() satisfies Promise<HeldawayAccessToken>,
  },
  status: {
    queryKey: null,
    queryFn: () => getTermsAndConditions(),
  },
  user: {
    queryKey: null,
    queryFn: () => getUserTermsAndConditions(),
  },
  verifiedAccounts: {
    queryKey: null,
    queryFn: () => getVerifiedAccounts(),
  },
  userEligibility: {
    queryKey: null,
    queryFn: () => getUserEligibility(),    
  },
  associateRestriction: {
    queryKey: null,
    queryFn: () => getAssociateRestriction()
  },
  netWorthYTD: {
    queryKey: null,
    queryFn: () => getNetWorthYTD()
  },
  refreshAccountsWaitForData: {
    queryKey: null,
    queryFn: async () => {
      const result = await getAccountRefresh();
      if(result?.IsRefreshed){ return result; }
      throw Error('Refresh account stale data check');
    }
  }
});

export async function getAssociateRestriction(): Promise<boolean> {
  const result = await bolApi.get(`${GATEWAY_PATH}/User/AssociateRestriction`);
  return !result.ok;
}

export async function getNetWorthYTD(): Promise<YodleeNetWorthDTO[]>{
  const currentDate = new Date();
  let firstDate = new Date();
  firstDate = new Date(firstDate.setMonth(firstDate.getMonth() - 2));
  firstDate.setDate(1);

  const result = await getJson<YodleeNetWorthDTO[]>(`${GATEWAY_PATH}/NetWorth?fromDate=${firstDate.toISOString()}&toDate=${currentDate.toISOString()}&interval=M`);
  return result;
}

export async function getAccountRefresh() : Promise<AccountsRefreshedResult>{
  const result = await getJson<AccountsRefreshedResult>(`${GATEWAY_PATH}/Account/Refresh`);
  return result;
}

export type RegType = "Verification" | "Aggregation";

export function useRegisterUserMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (type: RegType) => {
      if (type == "Verification"){
        return bolApi.post(`${GATEWAY_PATH}/User/Register/Verification`).json() }
      else {
        return bolApi.post(`${GATEWAY_PATH}/User/Register`).json()
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(heldawayQueryKeys.register);
    },
  });
}

export function useRefreshAccountsWaitForRefresh(){
  const queryClient = useQueryClient();
  return useQuery({
      ...heldawayQueryKeys.refreshAccountsWaitForData,
      retry: (failureCount: number, result: AccountsRefreshedResult) => {
        if(failureCount === 1){
          bolApi.put(`${GATEWAY_PATH}/Account/Refresh`).json();
        }
        const resultValue = (result.IsRefreshed === false) && failureCount < 10;
        if(!resultValue){
          queryClient.invalidateQueries(heldawayQueryKeys.netWorthYTD);
        }
        return resultValue;
      },
      retryDelay: 3000,
      staleTime: 900000, //15 minutes      
    });
}

export type VerifiedAccountsDetail = {
  account: VerifiedAccountDetail[];
};

export type VerifiedAccountDetail = {
  providerAccountId: string;
  accountName: string;
  accountNumber: string;
  id: number;
  providerName: string;
  accountType: string;
  routingNumber: string;
};

export enum TermsStatus {
  NotSet = 0,
  Accepted = 1,
  Declined = 2,
}

async function getTermsAndConditions() {
  return getText(`${GATEWAY_PATH}/TermsAndConditions`);
}

async function postUserTermsAndConditions(status: TermsStatus) {
  return postJsonWithNullBody(
    `${GATEWAY_PATH}/UserTermsAndConditions?statuscode=${status}`,
  );
}

async function getUserTermsAndConditions() {

  const text = await getText(`${GATEWAY_PATH}/UserTermsAndConditions`);
  
  if (!text) {
    return TermsStatus.NotSet;
  }
  return TermsStatus[text as keyof typeof TermsStatus];
}

async function getVerifiedAccounts() {
  const accounts = await getJson<VerifiedAccountsDetail>(
    `${GATEWAY_PATH}/Verification/Accounts`,
  );
  return accounts;
}

async function getUserEligibility(){
  const eligibility = await getJson<boolean>(
    `${GATEWAY_PATH}/User/Eligibility`,
  );
  return eligibility;
}

export function useHeldawayUserEligibilityQueryOptions(){
  const defaultData: boolean = true;
  return queryOptions({
    queryKey: heldawayQueryKeys.userEligibility.queryKey,
    queryFn: (context) =>
      (heldawayQueryKeys.userEligibility.queryFn(context) as Promise<boolean>).catch(
        (_) => defaultData,
      ),
    staleTime: Infinity,
    gcTime: 0
  });
}

export function useAssociateRestrictionQueryOptions(){
  const defaultData: boolean = true;
  return queryOptions({
    queryKey: heldawayQueryKeys.associateRestriction.queryKey,
    queryFn: (context) =>
      (heldawayQueryKeys.associateRestriction.queryFn(context) as Promise<boolean>).catch(
        (_) => defaultData,
      ),
    staleTime: Infinity,
    gcTime: 0
  });
}

export function useTsAndCsTextQueryOptions() {
  const defaultData: string = "";
  return queryOptions({
    queryKey: heldawayQueryKeys.status.queryKey,
    queryFn: (context) =>
      (heldawayQueryKeys.status.queryFn(context) as Promise<string>).catch(
        (_) => defaultData,
      ),
    staleTime: 0,
  });
}


export function useTsAndCsSetStatus() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (status: TermsStatus) => postUserTermsAndConditions(status),
    onSettled: () => queryClient.invalidateQueries(heldawayQueryKeys.user),
  });
}

export function useVerifiedAccountsQueryOptions() {
  const defaultData: VerifiedAccountsDetail = { account: [] };
  return queryOptions({
    queryKey: heldawayQueryKeys.verifiedAccounts.queryKey,
    queryFn: (context) =>
      (
        heldawayQueryKeys.verifiedAccounts.queryFn(
          context,
        ) as Promise<VerifiedAccountsDetail>
      ).catch((_) => defaultData),
    throwOnError: false,
  });
}

export function useAccessTokenQuery() {
  return useQuery(heldawayQueryKeys.accessToken);
}

export function useRegisterQuery() {
  return useQuery(heldawayQueryKeys.register);
}
