import { Segment } from "#app/(unauthorized)/authentication/segment";
import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { getBlob, getJson, postJsonWithResponse } from "@/lib/fetchClient";
import {
    createQueryKeys,
    mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import { keepPreviousData, queryOptions, useQuery } from "@tanstack/react-query";
import { type DocumentMetaData, generateDocumentsDateQuery } from "./all-documents-api";

export type FinancialPlanStatus = {
  canUseFinancialPlanning: boolean;
  hasVisiblePlans: boolean;
  hasVisibleDocuments: boolean;
};

export type FinancialPlan = {
  id: number;
  householdId: string;
  name: string;
  created: Date;
  branchId: string;
  lastName: string;
  firstName: string;
  userId: string;
  householdName: string;
  plantype: string;
  datePlanCreated: Date;
  datePlanUpdated: Date;
  isRecommended: boolean;
  planDescription: string;
};

export type Household = {
  id: string;
  name: string;
  created: Date;
};

type HouseholdClient = {
  retirementAge: number;
  retirementYear: number;
  retirementDescription: string;
  planningAge: number;
  planningYear: number;
};

export type HouseholdPlanGoal = {
  id: string;
  friendlyId: string;
  implementation: {
    namespace: string;
    name: string;
  };
  typeId: number;
  name: string;
  importance: number;
  importanceType: string;
  subRanking: number;
  expenseAmount: number;
  totalExpenseAmount: number;
  startYearKind: string;
  startYearEntered: number;
  endYearKind: string;
  occurrences: number;
  expensesPerYear: number;
  yearsBetweenExpenses: number;
  dateCreatedUtc: Date;
  dateUpdatedUtc: Date;
  summary: {
    client: HouseholdClient;
    coclient: HouseholdClient;
    expensePeriods: Array<{
      startYear: number;
      endYear: number;
      periodSummary: string;
      amount: number;
      recurrenceSummary: string;
    }>;
    name: string;
    attendingSummary: string;
    outsideFundingSummary: string;
  };
  url: string;
  isValid: boolean;
  isRecurring: boolean;
  imageUrl: string;
  imageAltText: string;
};

export type HouseholdPlanSuccessMeter = {
  targetLow: number;
  targetHigh: number;
  probabilityOfSuccess: number;
};

export type HouseholdPlanExpenseTimeline = {
  client: HouseholdClient;
  coclient: HouseholdClient;
  expenses: Array<{
    goalId: string;
    name: string;
    importance: number;
    expensesByYear: {
      additionalProp1: {
        year: number;
        amount: number;
        amountInflated: number;
      };
      additionalProp2: {
        year: number;
        amount: number;
        amountInflated: number;
      };
      additionalProp3: {
        year: number;
        amount: number;
        amountInflated: number;
      };
    };
  }>;
};

export type HouseholdSsoToken = {
  samlResponse: string;
  postUrl: string;
};

export type CreateLifeChangeBody =
  | {
      topics: 1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | 1024;
      extraNote?: string;
    }
  | {
      topics: 2048;
      extraNote: string;
    };

const GATEWAY_PATH = "financialplans/v1";

export async function getFinancialPlanStatus(): Promise<FinancialPlanStatus> {
  return await getJson<FinancialPlanStatus>(
    `/${GATEWAY_PATH}/FinancialPlan/Status`,
  );
}

export async function getFinancialPlanPdf(planId: number): Promise<Blob> {
  return await getBlob(
    `/${GATEWAY_PATH}/FinancialPlan/${encodeURIComponent(planId)}/pdf`,
  );
}

export async function getFinancialPlanPdfSingleHousehold(
  householdId: string,
): Promise<Blob> {
  return await getBlob(
    `/${GATEWAY_PATH}/FinancialPlan/FinancialPlanPdf?householdId=${encodeURIComponent(householdId)}`,
  );
}

export async function getFinancialPlansSingleHousehold(
  householdId: string,
): Promise<FinancialPlan[]> {
  return await getJson<FinancialPlan[]>(
    `/${GATEWAY_PATH}/FinancialPlan?householdId=${encodeURIComponent(householdId)}`,
  );
}

export async function getFinancialPlansDocumentsSingleHousehold(
  householdId: string,
  beginDate: Date,
  endDate: Date
): Promise<DocumentMetaData[]> {
  const dateQuery = generateDocumentsDateQuery({accounts:[], beginDate: beginDate, endDate: endDate})
  return await getJson<DocumentMetaData[]>(
    `/${GATEWAY_PATH}/FinancialPlan/Documents?householdId=${encodeURIComponent(householdId)}&${dateQuery}`,
  );
}

export async function getFinancialPlansMultipleHouseholds(
  householdsIds: Array<Household["id"]>,
): Promise<FinancialPlan[]> {
  const plansPromises = householdsIds.map((id) =>
    getFinancialPlansSingleHousehold(id),
  );
  const plansResults = await Promise.all(plansPromises);
  return plansResults.flat();
}

export async function getFinancialPlansDocumentsMultipleHouseholds(
  householdsIds: Array<Household["id"]>,
  beginDate: Date,
  endDate: Date
): Promise<DocumentMetaData[]> {
  const plansPromises = householdsIds.map((id) =>
    getFinancialPlansDocumentsSingleHousehold(id, beginDate, endDate),
  );
  const plansResults = await Promise.all(plansPromises);
  return plansResults.flat();
}

export async function getFinancialPlansGoalPlans(): Promise<FinancialPlan[]> {
  return await getJson<FinancialPlan[]>(
    `/${GATEWAY_PATH}/FinancialPlan/GoalPlans`,
  );
}
export async function getFinancialPlansViewablePlans(): Promise<
  FinancialPlan[]
> {
  return await getJson<FinancialPlan[]>(
    `/${GATEWAY_PATH}/FinancialPlan/ViewablePlans`,
  );
}

export async function getHouseholds(): Promise<Household[]> {
  return await getJson<Household[]>(`/${GATEWAY_PATH}/Household`);
}

export function useGetHouseholdsQuery(){
  return queryOptions({
    ...householdQueryKeys.all,
    placeholderData: keepPreviousData
  });
}

export async function getHouseholdPlanGoals(
  householdId: string,
): Promise<HouseholdPlanGoal[]> {
  return await getJson<HouseholdPlanGoal[]>(
    `/${GATEWAY_PATH}/Household/${encodeURIComponent(householdId)}/Goals`,
  );
}

export async function getHouseholdPlanSuccessMeter(
  householdId: string,
): Promise<HouseholdPlanSuccessMeter> {
  return await getJson<HouseholdPlanSuccessMeter>(
    `/${GATEWAY_PATH}/Household/${encodeURIComponent(householdId)}/SuccessMeter`,
  );
}

export async function getHouseholdPlanExpenseTimeline(
  householdId: string,
): Promise<HouseholdPlanExpenseTimeline> {
  return await getJson<HouseholdPlanExpenseTimeline>(
    `/${GATEWAY_PATH}/Household/${encodeURIComponent(householdId)}/ExpenseTimeline`,
  );
}

export async function getHouseholdSsoToken(
  householdId: string,
): Promise<HouseholdSsoToken> {
  return await getJson<HouseholdSsoToken>(
    `/${GATEWAY_PATH}/Household/${encodeURIComponent(householdId)}/SSO`,
  );
}

export function useHouseholdSsoTokenQuery(householdId: string){
  return queryOptions({
    ...householdQueryKeys.ssoToken(householdId),
    placeholderData: keepPreviousData
  });
}

export async function createLifeChange(body: CreateLifeChangeBody) {
  return await postJsonWithResponse(`/${GATEWAY_PATH}/LifeChange`, {
    json: body,
  });
}

const financialPlanQueryKeys = createQueryKeys("FinancialPlan", {
  pdfByPlanId: (planId: number) => ({
    queryKey: [planId],
    queryFn: () => getFinancialPlanPdf(planId),
  }),
  pdfByHouseholdId: (householdId: string) => ({
    queryKey: [householdId],
    queryFn: () => getFinancialPlanPdfSingleHousehold(householdId),
  }),
  plansByHouseholdId: (householdId: string) => ({
    queryKey: [householdId],
    queryFn: () => getFinancialPlansSingleHousehold(householdId),
  }),
  plansByHouseholdsIds: (householdsIds: Array<Household["id"]>) => ({
    queryKey: [householdsIds],
    queryFn: () => getFinancialPlansMultipleHouseholds(householdsIds),
  }),
  goalPlans: {
    queryKey: null,
    queryFn: () => getFinancialPlansGoalPlans(),
  },
  viewablePlans: {
    queryKey: null,
    queryFn: () => getFinancialPlansViewablePlans(),
  },
  status: {
    queryKey: null,
    queryFn: () => getFinancialPlanStatus(),
  },
  documentsByHouseholdIds: (householdsIds: Array<Household["id"]>, beginDate: Date, endDate: Date) => ({
    queryKey: [householdsIds, beginDate, endDate],
    queryFn: () => getFinancialPlansDocumentsMultipleHouseholds(householdsIds, beginDate, endDate)
  }),
});

const householdQueryKeys = createQueryKeys("Household", {
  all: {
    queryKey: null,
    queryFn: () => getHouseholds(),
  },
  planGoals: (householdId: string) => ({
    queryKey: [householdId],
    queryFn: () => getHouseholdPlanGoals(householdId),
  }),
  planSuccessMeter: (householdId: string) => ({
    queryKey: [householdId],
    queryFn: () => getHouseholdPlanSuccessMeter(householdId),
  }),
  planExpenseTimeline: (householdId: string) => ({
    queryKey: [householdId],
    queryFn: () => getHouseholdPlanExpenseTimeline(householdId),
  }),
  ssoToken: (householdId: string) => ({
    queryKey: [householdId],
    queryFn: () => getHouseholdSsoToken(householdId),
  }),
});

const lifeChangeQueryKeys = createQueryKeys("LifeChange", {
  create: (body: CreateLifeChangeBody) => ({
    queryKey: [body],
    queryFn: () => createLifeChange(body),
  }),
});

export const financialPlansQueryKeys = mergeQueryKeys(
  financialPlanQueryKeys,
  householdQueryKeys,
  lifeChangeQueryKeys,
);

const defaultFinancialPlanStatus: FinancialPlanStatus = {
  canUseFinancialPlanning: false,
  hasVisibleDocuments: false,
  hasVisiblePlans: false,
};

export function useQueryHasFinancialPlansAccess() {
  const jwt = useJwt();
  const hasRequiredActivities = jwt.requiredActivities.length > 0;
  const isAuthorized = [
    Segment.IndividualInvestors,
    Segment.PrivateAssetManagement,
    Segment.Preclient,
  ].includes(jwt.segment);
  return !hasRequiredActivities && isAuthorized;
}

export function useFinancialPlansStatusQueryOptions() {
  const hasFinancialPlansAccess = useQueryHasFinancialPlansAccess();
  return queryOptions({
    queryKey: financialPlansQueryKeys.FinancialPlan.status.queryKey,
    queryFn: (context) => {
      if (!hasFinancialPlansAccess) {
        return defaultFinancialPlanStatus;
      }
      return (
        financialPlansQueryKeys.FinancialPlan.status.queryFn(
          context,
        ) as Promise<FinancialPlanStatus>
      ).catch(() => defaultFinancialPlanStatus);
    },
    staleTime: hasFinancialPlansAccess ? Infinity : 0,
    gcTime: hasFinancialPlansAccess ? 0 : undefined,
    throwOnError: false,
  });
}

export function useQueryFinancialPlansAll() {
  const hasFinancialPlansAccess = useQueryHasFinancialPlansAccess();

  const statusResult = useQuery({
    ...financialPlansQueryKeys.FinancialPlan.status,
    enabled: hasFinancialPlansAccess,
  });

  const hasVisibleDocuments =
    statusResult.isSuccess && statusResult.data.hasVisibleDocuments;

  const householdsResult = useQuery({
    ...financialPlansQueryKeys.Household.all,
    enabled: hasVisibleDocuments,
  });

  const hasHouseholdsIds =
    householdsResult.isSuccess && householdsResult.data.length > 0;
  const householdsIds = hasHouseholdsIds
    ? householdsResult.data.map((el) => el.id)
    : [];

    const plansByHouseholdsIdsResult = useQuery({
      ...financialPlansQueryKeys.FinancialPlan.plansByHouseholdsIds(
        householdsIds,
      ),
      enabled: hasHouseholdsIds,
    });

  const data = plansByHouseholdsIdsResult.data ?? [];

  const error =
    statusResult.error ??
    householdsResult.error ??
    plansByHouseholdsIdsResult.error;

  const isPending =
    statusResult.isPending ||
    (hasVisibleDocuments &&
      (householdsResult.isPending ||
        (hasHouseholdsIds && plansByHouseholdsIdsResult.isPending)));

  const isError =
    statusResult.isError ||
    householdsResult.isError ||
    plansByHouseholdsIdsResult.isError;

  return {
    ...plansByHouseholdsIdsResult,
    data,
    error,
    isError,
    isPending,
    isVisible: hasVisibleDocuments,
  };
}



export function useQueryFinancialPlansDocumentsAll(beginDate: Date, endDate: Date) {
  const hasFinancialPlansAccess = useQueryHasFinancialPlansAccess();

  const statusResult = useQuery({
    ...financialPlansQueryKeys.FinancialPlan.status,
    enabled: hasFinancialPlansAccess,
  });

  const hasVisibleDocuments =
    statusResult.isSuccess && statusResult.data.hasVisibleDocuments;

  const householdsResult = useQuery({
    ...financialPlansQueryKeys.Household.all,
    enabled: hasVisibleDocuments,
  });

  const hasHouseholdsIds =
    householdsResult.isSuccess && householdsResult.data.length > 0;
  const householdsIds = hasHouseholdsIds
    ? householdsResult.data.map((el) => el.id)
    : [];

    const plansDocumentsByHouseholdsIdsResult = useQuery({
      ...financialPlansQueryKeys.FinancialPlan.documentsByHouseholdIds(
        householdsIds,
        beginDate,
        endDate
      ),
      enabled: hasHouseholdsIds,
    });

  const data = plansDocumentsByHouseholdsIdsResult.data ?? [];

  const error =
    statusResult.error ??
    householdsResult.error ??
    plansDocumentsByHouseholdsIdsResult.error;

  const isPending =
    statusResult.isPending ||
    (hasVisibleDocuments &&
      (householdsResult.isPending ||
        (hasHouseholdsIds && plansDocumentsByHouseholdsIdsResult.isPending)));

  const isError =
    statusResult.isError ||
    householdsResult.isError ||
    plansDocumentsByHouseholdsIdsResult.isError;

  const isSuccess = 
    statusResult.isSuccess ||
    (hasVisibleDocuments &&
      (householdsResult.isSuccess ||
        (hasHouseholdsIds && plansDocumentsByHouseholdsIdsResult.isSuccess)));

  return {
    ...plansDocumentsByHouseholdsIdsResult,
    data,
    error,
    isError,
    isPending,
    isVisible: hasVisibleDocuments,
    isSuccess: isSuccess
  };
}

export function useQuerySuccessMeter() {
  const {
    data: householdData,
    isPending: isPendingId,
    isError: isErrorId,
  } = useQuery(financialPlansQueryKeys.Household.all);
  const households = householdData?.sort((a, b) =>
    a.created > b.created ? -1 : 1,
  );
  const [household] = households || [];
  const { id = "" } = household || {};
  const { data, isPending, isError } = useQuery({
    ...financialPlansQueryKeys.Household.planSuccessMeter(id),
    enabled: !!id,
  });

  return {
    data,
    isPending: isPending || isPendingId,
    isError: isError || isErrorId,
  };
}

export function useQuerySuccessMeterByHousehold(id: string) {
  return useQuery({
    ...financialPlansQueryKeys.Household.planSuccessMeter(id),
    enabled: !!id && id.length > 0,
  });
}
