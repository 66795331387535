import { createQueryKeyStore } from "@lukemorales/query-key-factory";
import { getAccounts } from "./external-bol-account-service/get-accounts";
import { getSharedSubscribed } from "./external-bol-account-service/get-shared-subscribed";
import {
  getSharedSubscribers,
  type GetSharedSubscribersParams,
} from "./external-bol-account-service/get-shared-subscribers";
import { getSharingInvites } from "./external-bol-account-service/get-sharing-invites";
import { getSharingTermsAndConditionsOwner } from "./external-bol-account-service/get-sharing-terms-and-conditions-owner";
import { getSharingTermsAndConditionsViewer } from "./external-bol-account-service/get-sharing-terms-and-conditions-viewer";
import { getAccountGroups } from "./external-user-profile-service/get-account-groups";
import { getAccountGroupsPortfolios } from "./pwm-portfolio-service/get-accounts-groups-portfolios";
import {
  getAssetAllocations,
  type GetAssetAllocationsParams,
} from "./pwm-portfolio-service/get-asset-allocations";
import {
  getHistoricalValues,
  type GetHistoricalValuesParams,
} from "./pwm-portfolio-service/get-historical-values";
import {
  getPortfolio,
  type GetPortfolioParams,
} from "./pwm-portfolio-service/get-portfolio";
import {
  getRecentActivity,
  type GetRecentActivityParams,
} from "./pwm-portfolio-service/get-recent-activity";
import {
  getWatchlistByName,
  type GetWatchlistByNameParams,
} from "./pwm-portfolio-service/get-watchlist-by-name";
import { getWatchlistNames } from "./pwm-portfolio-service/get-watchlist-names";

/**
 * All query keys in one file to:
 * - Prevent cache pollution/conflicts/duplications
 * - Expose best-practice naming and patterns
 *
 * Make sure to alpha-sort keys for better DX.
 */
export const allQueryKeys = createQueryKeyStore({
  bolAuthenticationService: {},

  externalBairdResearchService: {},

  externalBolAccountService: {
    accounts: {
      queryKey: null,
      queryFn: ({ signal }) => getAccounts({ signal }),
    },
    sharedSubscribed: {
      queryKey: null,
      queryFn: ({ signal }) => getSharedSubscribed({ signal }),
    },
    sharedSubscribers: (params: GetSharedSubscribersParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getSharedSubscribers({ ...params, signal }),
    }),
    sharingInvites: {
      queryKey: null,
      queryFn: ({ signal }) => getSharingInvites({ signal }),
    },
    sharingTermsAndConditionsOwner: {
      queryKey: null,
      queryFn: ({ signal }) => getSharingTermsAndConditionsOwner({ signal }),
    },
    sharingTermsAndConditionsViewer: {
      queryKey: null,
      queryFn: ({ signal }) => getSharingTermsAndConditionsViewer({ signal }),
    },
  },

  externalBolNotificationService: {},

  externalBolRegistrationService: {},

  externalCashManagementService: {},

  externalClientsCommunicationsService: {},

  externalConfirmationsService: {},

  externalEDeliveryService: {},

  externalFinancialPlanService: {},

  externalFundTransferService: {},

  externalHeldawayService: {},

  externalMarketingService: {},

  externalMarketResearchService: {},

  externalPerformanceReportingService: {},

  externalPwmAddressService: {},

  externalSecureDocumentService: {},

  externalStatementsService: {},

  externalTaxDocumentsService: {},

  externalTrustService: {},

  externalUserProfileService: {
    accountGroups: {
      queryKey: null,
      queryFn: ({ signal }) => getAccountGroups({ signal }),
    },
  },

  pwmPortfolioService: {
    accountGroupsPortfolios: {
      queryKey: null,
      queryFn: ({ signal }) => getAccountGroupsPortfolios({ signal }),
    },
    assetAllocations: (params: GetAssetAllocationsParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getAssetAllocations({ ...params, signal }),
    }),
    historicalValues: (params?: GetHistoricalValuesParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getHistoricalValues({ ...params, signal }),
    }),
    portfolio: (params?: GetPortfolioParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getPortfolio({ ...params, signal }),
    }),
    recentActivity: (params?: GetRecentActivityParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getRecentActivity({ ...params, signal }),
    }),
    watchlistByName: (params: GetWatchlistByNameParams) => ({
      queryKey: [params],
      queryFn: ({ signal }) => getWatchlistByName({ ...params, signal }),
    }),
    watchlistNames: {
      queryKey: null,
      queryFn: ({ signal }) => getWatchlistNames({ signal }),
    },
  },
});
