import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Subscribers } from "./model-types";

export type GetSharedSubscribersParams = { accountNumber: string };

export type GetSharedSubscribersData = Subscribers;

export async function getSharedSubscribers(
  params: GetSharedSubscribersParams & Pick<Options, "signal">,
): Promise<GetSharedSubscribersData> {
  // TODO: Remove placeholder data
  return new Promise<GetSharedSubscribersData>((res) =>
    res([
      {
        account: {
          name: "Terri Smith Roth",
          accountNumber: 123456789,
          accountType: "Traditional Roth",
        },
        dateAdded: "",
        viewers: [
          { loginId: "", email: "js@example.com", dateAdded: "" },
          { loginId: "", email: "ms@example.com", dateAdded: "" },
          { loginId: "", email: "wl@example.com", dateAdded: "" },
          { loginId: "", email: "ap@example.com", dateAdded: "" },
          { loginId: "", email: "xy@example.com", dateAdded: "" },
        ],
      },
      {
        account: {
          name: "Terri Smith RIRA",
          accountNumber: 543219876,
          accountType: "Roth IRA",
        },
        dateAdded: "",
        viewers: [
          { loginId: "", email: "js@example.com", dateAdded: "" },
          { loginId: "", email: "ms@example.com", dateAdded: "" },
          { loginId: "", email: "wl@example.com", dateAdded: "" },
          { loginId: "", email: "ap@example.com", dateAdded: "" },
        ],
      },
      {
        account: {
          name: "Terri Smith Roth",
          accountNumber: 912345678,
          accountType: "529 Plan",
        },
        dateAdded: "",
        viewers: [
          { loginId: "", email: "js@example.com", dateAdded: "" },
          { loginId: "", email: "ms@example.com", dateAdded: "" },
          { loginId: "", email: "wl@example.com", dateAdded: "" },
        ],
      },
      {
        account: {
          name: "Terri Smith Roth",
          accountNumber: 3219876541,
          accountType: "Brokerage",
        },
        dateAdded: "",
        viewers: [
          { loginId: "", email: "js@example.com", dateAdded: "" },
          { loginId: "", email: "ms@example.com", dateAdded: "" },
        ],
      },
      {
        account: {
          name: "Terri Smith Roth",
          accountNumber: 3219876542,
          accountType: "Brokerage",
        },
        dateAdded: "",
        viewers: [{ loginId: "", email: "js@example.com", dateAdded: "" }],
      },
      {
        account: {
          name: "Terri Smith Roth",
          accountNumber: 3219876543,
          accountType: "Brokerage",
        },
        dateAdded: "",
        viewers: [],
      },
    ]),
  );

  // Parse incoming
  const { accountNumber } = parseParams(params);

  // Call endpoint
  const data = await getJson<GetSharedSubscribersData>(
    `${GATEWAY_PATHS.externalBolAccountService}/Shared/Subscribers/${accountNumber}`,
    { signal: params.signal },
  );

  // Success
  return data;
}

function parseParams(params: GetSharedSubscribersParams) {
  const accountNumber = encodeURIComponent(params.accountNumber);
  return { accountNumber };
}
