import { getJson, type Options } from "#app/lib/fetchClient";
import { GATEWAY_PATHS } from "../api-utils";
import { type Subscribed } from "./model-types";

export type GetSharedSubscribedData = Subscribed;

export async function getSharedSubscribed(
  params: Pick<Options, "signal">,
): Promise<GetSharedSubscribedData> {
  // TODO: Remove placeholder data
  return await new Promise<GetSharedSubscribedData>((res) =>
    res([
      {
        name: "Terri Smith Roth",
        accountNumber: 123456789,
        accountType: "Traditional Roth",
        dateShared: new Date().toISOString(),
        owners: [
          { firstname: "Jerry", lastname: "Smith", email: "js@example.com" },
        ],
      },
      {
        name: "Terri Smith RIRA",
        accountNumber: 543219876,
        accountType: "Roth IRA",
        dateShared: new Date().toISOString(),
        owners: [{ firstname: null, lastname: null, email: "js@example.com" }],
      },
    ]),
  );

  // Call endpoint
  const data = await getJson<GetSharedSubscribedData>(
    `${GATEWAY_PATHS.externalBolAccountService}/Shared/Subscribed`,
    { signal: params.signal },
  );

  // Success
  return data;
}
